import {Component, Input} from "@angular/core";
import {IRegularExerciseConfigForm} from "../../../../../../../core/forms/factory/RegularExerciseFormFactory";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ExerciseHelper} from "../../../../../../../core/helpers/exercise.helper";
import {RegularExerciseHelper} from "../../../../../../../core/forms/helpers/RegularExerciseHelper";
import {IFullRegularExerciseForm} from "../../../../../workout-form/WorkoutFormFactory";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatInputModule} from "@angular/material/input";
import {ValidationHelperService} from "../../../../../../../core/helpers/validation-helper.service";

@Component({
  selector: "app-regular-exercise-config-row",
  templateUrl: "./regular-exercise-config-row.component.html",
  styleUrls: ["./regular-exercise-config-row.component.scss"],
  imports: [
    NgOptimizedImage,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatInputModule,
    NgIf
  ],
  standalone: true
})
export class RegularExerciseConfigRowComponent {
  @Input() configForm: FormGroup<IRegularExerciseConfigForm>;
  @Input() exerciseForm: FormGroup<IFullRegularExerciseForm>;

  constructor(private exerciseHelper: ExerciseHelper) {
  }

  getConfigTitle(): string {
    const exerciseConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm.controls.type.value, this.configForm);
    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, exerciseConfig);
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
