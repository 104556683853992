<div>
  <app-count-exercise-form
    *ngIf="type.value === 0"
    [_formGroup]="countForm"
  ></app-count-exercise-form>
  <app-react-exercise-form
    *ngIf="type.value === 1"
    [_formGroup]="reactForm"
  ></app-react-exercise-form>
  <app-sequence-exercise-form
    [_formGroup]="sequenceForm"
    *ngIf="type.value == 2"
  ></app-sequence-exercise-form>
  <app-agility-exercise-form
    *ngIf="type.value == 3"
    [_formGroup]="agilityForm"
  ></app-agility-exercise-form>
  <app-vertical-exercise-form
    *ngIf="type.value === 4"
    [_formGroup]="verticalForm"
  ></app-vertical-exercise-form>
  <app-countdowns-form
    *ngIf="type.value !== 4"
    [_formGroup]="countdownsForm"
    [_exerciseType]="type"
  ></app-countdowns-form>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>Difficulty</mat-label>
        <mat-select
                placeholder="Difficulty"
                [formControl]="difficulty"
                [value]="difficulty.value"
        >
            <mat-option [value]="1">Beginner</mat-option>
            <mat-option [value]="2">Intermediate</mat-option>
            <mat-option [value]="3">Advanced</mat-option>
        </mat-select>
        <mat-error *ngIf="ValidationHelperService.isInvalid(difficulty)">
            {{ ValidationHelperService.getErrorMessage(difficulty) }}
        </mat-error>
    </mat-form-field>

</div>
