import {Component} from "@angular/core";
import {PlaylistFormFactory} from "../workout-form/WorkoutFormFactory";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-create-playlist-dialog',
  templateUrl: './create-playlist-dialog.component.html',
  styleUrls: ['./create-playlist-dialog.component.scss'],
  host: {
    class: 'h-full'
  }
})
export class CreatePlaylistDialogComponent {
  _form = PlaylistFormFactory.create();

  constructor(
      private dialogRef: MatDialogRef<CreatePlaylistDialogComponent>,
      private dialog: MatDialog
  ) {}

  openCloseDialog(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "You will lose all your progress."
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (res) => {
        if(res) this.dialog.closeAll();
      }
    })
  }


  onSave(): void {
    this._form.markAllAsTouched();
    if(this._form.controls.name.valid) this.dialogRef.close(this._form);
  }
  
}
