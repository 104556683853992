import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {IRegularExerciseConfig} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {ICategory, IExercise} from "../../../shared/interfaces/ICategory";
import {EBodyPart} from "../../../shared/enums/EBodyPart";
import {CustomValidators} from "../../../core/helpers/custom-validators";
import {EExerciseStartType} from "../../../shared/enums/EExerciseStartType";
import {IRegularExerciseConfigForm, RegularExerciseConfigFormFactory} from "../../../core/forms/factory/RegularExerciseFormFactory";
import {IExerciseConfig} from "../../exercises/exercise-form/exercise-form.component";

export interface IPlaylistConfigForm {
    configId: FormControl<string>;
    isPaired: FormControl<boolean>;
    sets: FormControl<number>;
}

export interface IPlaylistForm {
    name: FormControl<string>;
    isAutomated: FormControl<boolean>;
    isCircuit: FormControl<boolean>;
    circuitSets: FormControl<number>;
    restTime: FormControl<number>;
    exerciseCategories: FormArray<FormGroup<IExercisesCategoryForm>>;
}

export interface IFullRegularExerciseForm {
    active: FormControl<boolean>;
    name: FormControl<string>;
    category: FormControl<ICategory>;
    type: FormControl<number>;
    videoUrl: FormControl<string>;
    imgThumb: FormControl<string>;
    bodyPart: FormControl<EBodyPart>;
    configs: FormArray<FormGroup<IRegularExerciseConfigForm>>;
}

export interface IFullRegularExercise {
    active: boolean;
    name: string;
    category: ICategory;
    type: number;
    videoUrl: string;
    imgThumb: string;
    bodyPart: EBodyPart;
    configs: IExerciseConfig[];
}

export interface IFullPairedExerciseForm {
    id: FormControl<string>;
    name: FormControl<string>;
    pairedExerciseConfigs: FormArray<FormGroup<IPairedExerciseConfigForm>>;
    type: FormControl<number>;
    firstExercise: FormGroup<IFullRegularExerciseForm>;
    secondExercise: FormGroup<IFullRegularExerciseForm>;
}

export interface IFullPairedExercise {
    id: string;
    name: string;
    pairedExerciseConfigs: IPairedExerciseConfig[];
    type: number;
    firstExercise: IFullRegularExercise;
    secondExercise: IFullRegularExercise;
}



export interface IPairedExerciseConfigForm {
    isSelected?: FormControl<boolean>;
    sets?: FormControl<number>;
    firstConfigId: FormControl<string>;
    secondConfigId: FormControl<string>;
    id: FormControl<string>;
}

export interface IPairedExerciseConfig {
    firstConfigId: string;
    secondConfigId: string;
    id: string;
}

export interface IExerciseForm {
    regularExercise: FormGroup<IFullRegularExerciseForm>,
    pairedExercise: FormGroup<IFullPairedExerciseForm>
}

export interface IExercisesCategoryForm {
    id: FormControl<string>;
    name: FormControl<string>;
    exercises: FormArray<FormGroup<IExerciseForm>>;
}

export interface IWorkoutForm {
    name: FormControl<string>;
    playlists: FormArray<FormGroup<IPlaylistForm>>;
}


export class WorkoutFormFactory {
    public static create(): FormGroup<IWorkoutForm> {
        return new FormGroup<IWorkoutForm>({
            name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]),
            playlists: new FormArray<FormGroup<IPlaylistForm>>([])
        })
    }
}

export class PlaylistFormFactory {
    public static create(): FormGroup<IPlaylistForm> {
        return new FormGroup<IPlaylistForm>({
            name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]),
            isAutomated: new FormControl<boolean>(true),
            isCircuit: new FormControl<boolean>(true),
            circuitSets:  new FormControl<number>(1, [Validators.required, Validators.min(0), Validators.max(255)]),
            restTime: new FormControl<number>(10, [Validators.required, Validators.min(0), Validators.max(255)]),
            exerciseCategories: new FormArray<FormGroup<IExercisesCategoryForm>>([])
        });
    }
}

export class ExercisesCategoryFormFactory {
    public static create(): FormGroup<IExercisesCategoryForm> {
        return new FormGroup<IExercisesCategoryForm>({
            id: new FormControl<string>(""),
            name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]),
            exercises: new FormArray<FormGroup<IExerciseForm>>([])
        });
    }
}

export class ExercisesCategoryFormHelper {
    public static patchFormValue(formGroup: FormGroup<IExercisesCategoryForm>, category: ICategory): void {
        formGroup.controls.id.setValue(category.id);
        formGroup.controls.name.setValue(category.name);
        formGroup.controls.exercises.clear();
        category.exercises.forEach(exercise => {
            const newFormExercise = ExerciseFormFactory.create();
            ExerciseFormHelper.patchFormValue(newFormExercise, exercise);
            formGroup.controls.exercises.push(newFormExercise);
        });
    }
}

export class FullRegularExerciseFormFactory {
    public static create(): FormGroup<IFullRegularExerciseForm> {
        return new FormGroup({
            active: new FormControl(true, [Validators.required]),
            name: new FormControl("", [Validators.required]),
            category: new FormControl(
                {
                    id: null,
                    name: null,
                },
                [CustomValidators.requiredCategoryValidator],
            ),
            bodyPart: new FormControl<EBodyPart>(null),
            type: new FormControl(0, [Validators.required]),
            videoUrl: new FormControl(""),
            imgThumb: new FormControl(""),
            configs: new FormArray<FormGroup<IRegularExerciseConfig>>([])
        });
    }
}

export class FullRegularExerciseFormHelper {
    public static patchFormValue(formGroup: FormGroup<IFullRegularExerciseForm>, exercise: IFullRegularExercise): void {
        formGroup.controls.imgThumb.setValue(exercise.imgThumb);
        formGroup.controls.active.setValue(exercise.active);
        formGroup.controls.type.setValue(exercise.type);
        formGroup.controls.bodyPart.setValue(exercise.bodyPart);
        formGroup.controls.name.setValue(exercise.name);
        formGroup.controls.videoUrl.setValue(exercise.videoUrl);
        for(let config of exercise.configs) {

            const newConfig = RegularExerciseConfigFormFactory.create();
            newConfig.controls.id.setValue(config.id);
            newConfig.controls.sequenceForm.controls.sequenceSensors.clear();
            config?.sensorsSequence?.forEach((val) =>
                newConfig.controls.sequenceForm.controls.sequenceSensors.push(
                    new FormControl(val),
                ),
            );

            newConfig.controls.agilityForm.controls.agilitySensors.clear();
            config?.arrows?.forEach((arrow: number) =>
                newConfig.controls.agilityForm.controls.agilitySensors.push(
                    new FormControl(arrow),
                ),
            );


            const value = {
                    difficulty: config.difficulty,
                    countdownForm: {
                        endType: config.countdownEndType,
                        endValue: config.countdownEnd,
                        startValue: config?.countdownStart,
                        startType: 1,
                    },
                    countForm: {
                        countType: config.sumType,
                    },
                    reactForm: {
                        delay: config.timerDelay,
                        timerPrompt: config.timerPrompt,
                        neurocognitiveType: config.neurocognitiveType,
                        stroopEffectType: config.stroopEffectType,
                        liftTime: config.liftTime,
                        footFireActivity: config.footFireActivity,
                        isolatedReact: config.isolatedReact,
                        reactTo: new Array(8).fill(false).map((value, index) => {
                            return config?.reactTo?.includes(index);
                        }),
                        dontReactTo: new Array(6).fill(false).map((value, index) => {
                            return config?.dontReactTo?.includes(index);
                        }),
                        activeSensors: new Array(5).fill(false).map((value, index) => {
                            return config?.activeSensors?.includes(index + 1);
                        }),
                    },
                    sequenceForm: {
                        delay: config.timerDelay,
                        sequenceSensors: config.sensorsSequence,
                        timerPrompt: config.timerPrompt,
                    },
                    agilityForm: {
                        delay: config.timerDelay,
                        agilityCustomType: config.arrowsType,
                        agilitySensors: config.arrows,
                        timerPrompt: config.timerPrompt
                    },
                    verticalForm: {
                        numberOfLegs: config.numberOfLegs,
                        verticalJumpType: config.jumpType,
                        numberOfJumps: config.jumpCount,
                        isVerticalReact: config.reactTo?.length > 0 ||  config.dontReactTo?.length > 0,
                        reactTo: new Array(8).fill(false).map((value, index) => {
                            return config?.reactTo?.includes(index);
                        }),
                        dontReactTo: new Array(6).fill(false).map((value, index) => {
                            return config?.dontReactTo?.includes(index);
                        }),
                    },

            };
            if (!config?.countdownStart) {
                delete value.countdownForm.startValue;
                value.countdownForm.startType = EExerciseStartType.RANDOM;
            }

            newConfig.patchValue(value);
            formGroup.controls.configs.push(newConfig);
        }

    }
}

export class FullPairedExerciseFormFactory {
    public static create(): FormGroup<IFullPairedExerciseForm> {
        return new FormGroup<IFullPairedExerciseForm>({
            id: new FormControl<string>(""),
            name: new FormControl<string>(""),
            pairedExerciseConfigs: new FormArray<FormGroup<IPairedExerciseConfigForm>>([]),
            type: new FormControl<number>(0),
            firstExercise: FullRegularExerciseFormFactory.create(),
            secondExercise: FullRegularExerciseFormFactory.create()
        });
    }
}

export class FullPairedExerciseFormHelper {
    public static patchFormValue(formGroup: FormGroup<IFullPairedExerciseForm>, exercise: IFullPairedExercise) {
        formGroup.controls.id.setValue(exercise.id);
        formGroup.controls.name.setValue(exercise.name);
        exercise.pairedExerciseConfigs.forEach(config => {
            formGroup.controls.pairedExerciseConfigs.push(new FormGroup<IPairedExerciseConfigForm>({
                isSelected: new FormControl<boolean>(false),
                sets: new FormControl<number>(1, [Validators.required, Validators.min(0), Validators.maxLength(255)]),
                id: new FormControl<string>(config.id),
                firstConfigId: new FormControl<string>(config.firstConfigId),
                secondConfigId: new FormControl<string>(config.secondConfigId)
            }));
        })
        FullRegularExerciseFormHelper.patchFormValue(formGroup.controls.firstExercise, exercise.firstExercise);
        FullRegularExerciseFormHelper.patchFormValue(formGroup.controls.secondExercise, exercise.secondExercise);
    }
}


export class ExerciseFormFactory {
    public static create(): FormGroup<IExerciseForm> {
        return new FormGroup<IExerciseForm>({
            regularExercise: FullRegularExerciseFormFactory.create(),
            pairedExercise: FullPairedExerciseFormFactory.create()
        });
    }
}

export class ExerciseFormHelper {
    public static patchFormValue(formGroup: FormGroup<IExerciseForm>, exercise: IExercise): void {
        if(exercise.regularExercise) FullRegularExerciseFormHelper.patchFormValue(formGroup.controls.regularExercise, exercise.regularExercise);
        if(exercise.pairedExercise) FullPairedExerciseFormHelper.patchFormValue(formGroup.controls.pairedExercise, exercise.pairedExercise);
    }
}
