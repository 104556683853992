import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

export interface ISportForm {
    name: FormControl<string>,
    isActive: FormControl<boolean>,
    positions: FormArray<FormGroup<IPositionForm>>
}

export interface IPositionForm {
    id: FormControl<string>,
    name: FormControl<string>,
    isActive: FormControl<boolean>
}

export class SportFormFactory {
    public static create(): FormGroup<ISportForm>
    {
        return new FormGroup<ISportForm>(
            {
                name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
                isActive: new FormControl<boolean>(true, [Validators.required]),
                positions: new FormArray<FormGroup<IPositionForm>>([])
            }
        )
    }
}
