import {Component, Input} from "@angular/core";
import {IDraggableRegularExerciseConfig} from "../playlist-row-form.component";

@Component({
  selector: 'app-draggable-exercise-config',
  templateUrl: './draggable-exercise-config.component.html',
  styleUrls: ['./draggable-exercise-config.component.scss']
})
export class DraggableExerciseConfigComponent {
  @Input() config: IDraggableRegularExerciseConfig;
}
