<div class="h-[56px] flex gap-5 items-center justify-between">
    <div class="flex gap-5 items-center">
        <mat-checkbox color="primary" #checkbox [formControl]="configForm.controls.isSelected"></mat-checkbox>
        <div class="flex items-center gap-2 hover:opacity-80 cursor-pointer" (click)="checkbox._onInputClick()">
            <div class="flex items-center">
                <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
            </div>
            <span  class="font-medium text-md">{{getConfigTitle()}}</span>
        </div>
    </div>
    <mat-form-field appearance="outline" class="w-[100px] mr-5" subscriptSizing="dynamic" *ngIf="configForm.controls.isSelected.value">
        <mat-label>Sets</mat-label>
        <input type="number" step="1" matInput [formControl]="configForm.controls.sets">
        <mat-error *ngIf="ValidationHelperService.isInvalid(configForm.controls.sets)">
            {{
                ValidationHelperService.getErrorMessage(configForm.controls.sets)
            }}
        </mat-error>
    </mat-form-field>
</div>
