import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {ApiService} from "../../api.service";
import {IGetWorkoutsPayload} from "./data/IGetWorkoutsPayload";
import {IGetWorkoutsResponse} from "./data/IGetWorkoutsResponse";
import {IWorkout} from "../../../../shared/interfaces/IWorkout";

@Injectable({
  providedIn: "root",
})
export class WorkoutsControllerService {
  private readonly _resourceUrl: string = "/workouts";

  constructor(private apiService: ApiService) {}

  getWorkouts(payload: IGetWorkoutsPayload): Observable<IGetWorkoutsResponse> {
    return this.apiService.get<IGetWorkoutsResponse>(
      `${this._resourceUrl}`,
      payload,
      AuthService.defaultTokenHeader().headers,
    );
  }

  createWorkout(newWorkout: Partial<IWorkout>): Observable<null> {
    const locationId = localStorage.getItem("selectedLocationId");
    return this.apiService.post<null>(
        `${this._resourceUrl}`,
        newWorkout,
        { locationId },
        AuthService.defaultTokenHeader().headers,
    );
  }

  deactivateWorkout(id: string): Observable<null> {
    return this.apiService.patch<null>(
        `${this._resourceUrl}/${id}/deactivate`,
        AuthService.defaultTokenHeader().headers,
    );
  }
}
