import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IPlaylistForm, IWorkoutForm} from "./WorkoutFormFactory";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {Router} from "@angular/router";
import {CreatePlaylistDialogComponent} from "../create-playlist-dialog/create-playlist-dialog.component";

export interface IWorkoutPayload {
  name: string;
  playlists: IPlaylistPayload[]
}

export interface IPlaylistPayload {
  name: string;
  isAutomated: boolean;
  isCircuit: boolean;
  circuitSets: number;
  restTime: number;
  playlistConfigs: IPlaylistConfig[]
}

export interface IPlaylistConfig {
  configId: string;
  isPaired: boolean;
  sets: number;
}

@Component({
  selector: 'app-workout-form',
  templateUrl: './workout-form.component.html',
  styleUrls: ['./workout-form.component.scss'],
  host: {
    class: 'h-full'
  }
})
export class WorkoutFormComponent {
  @Output() _submit = new EventEmitter<IWorkoutPayload>();
  @Input() _form: FormGroup<IWorkoutForm>;
  @Output() _cancel = new EventEmitter<null>();

  constructor(private dialog: MatDialog, private router: Router) {}

  onSubmit(): void {
    this._form.markAllAsTouched();
    if(this._form.controls.name.valid) this._submit.emit(this.createPayload());
  }

  createPayload(): IWorkoutPayload {
    return {
      name: this._form.controls.name.value,
      playlists: this.getPlaylistsPayload()
    }
  }

  getPlaylistsPayload(): IPlaylistPayload[] {
    const playlistsPayload: IPlaylistPayload[] = [];
    this._form.controls.playlists.controls.forEach(playlist => {
      playlistsPayload.push(this.getPlaylistPayload(playlist));
    })
    return playlistsPayload;
  }

  getPlaylistPayload(playlist: FormGroup<IPlaylistForm>): IPlaylistPayload {
    const playlistConfigs: IPlaylistConfig[] = [];
    playlist.controls.exerciseCategories.controls.forEach(exerciseCategory => {
      exerciseCategory.controls.exercises.controls.forEach(exercise => {
        exercise.controls.pairedExercise.controls.pairedExerciseConfigs.controls.forEach(pairedConfig => {
          if(pairedConfig.controls.isSelected.value) playlistConfigs.push({
            sets: pairedConfig.controls.sets.value,
            configId: pairedConfig.controls.id.value,
            isPaired: true
          })
        })
        exercise.controls.regularExercise.controls.configs.controls.forEach(config => {
          if(config.controls.isSelected.value) playlistConfigs.push({
            sets: config.controls.sets.value,
            configId: config.controls.id.value,
            isPaired: false
          })
        })
      })
    })
    return {
      name: playlist.controls.name.value,
      isAutomated: playlist.controls.isAutomated.value,
      isCircuit: playlist.controls.isCircuit.value,
      circuitSets: playlist.controls.circuitSets.value,
      restTime: playlist.controls.restTime.value,
      playlistConfigs
    }
  }



  cancel(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "Are you sure you want to cancel? All your progress will be lost."
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (res) => {
        if(res) this._cancel.emit();
      }
    })
  }

  addPlaylist(): void {
    this.dialog.open(CreatePlaylistDialogComponent, {
      maxHeight: '90vh',
      height: '90vh',
      disableClose: false,
      data: {
        _form: this._form,
      }
    }).afterClosed().subscribe({
      next: (res: FormGroup<IPlaylistForm>) => {
        if(res) this._form.controls.playlists.push(res);
      }
    })
  }

  deletePlaylist(playlistForm: FormGroup<IPlaylistForm>): void {
    this._form.controls.playlists.removeAt(this._form.controls.playlists.controls.indexOf(playlistForm));
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
