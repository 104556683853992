<form class="flex flex-col gap-3">
    <div class="flex justify-between gap-3">
        <p class="!m-0 flex items-center cursor-pointer" (click)="openEditDialog()">
            <span>{{_form.controls.name.value}}</span>
            <mat-icon class="scale-75 mb-1">edit</mat-icon>
        </p>
        <button mat-flat-button color="warn" type="button" (click)="openDeleteDialog()">
            Delete playlist
        </button>
    </div>

    <div class="bg-gray-100 rounded-2xl p-3">
        <div class="bg-white p-3 rounded-2xl flex items-center justify-between">
            <div class="grid grid-cols-2 gap-3 justify-center items-center">
                <p class="!m-0 text-gray-500 font-medium">Automated</p>
                <p class="!m-0 text-gray-500 font-medium">Circuit</p>
                <mat-slide-toggle color="primary" [formControl]="_form.controls.isAutomated"></mat-slide-toggle>
                <mat-slide-toggle color="primary" [formControl]="_form.controls.isCircuit"></mat-slide-toggle>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <mat-form-field appearance="outline" class="w-[10rem]" subscriptSizing="dynamic">
                    <mat-label>Circuit sets</mat-label>
                    <input type="number" matInput [formControl]="_form.controls.circuitSets">
                    <mat-error *ngIf="ValidationHelperService.isInvalid(_form.controls.circuitSets)">
                        {{
                            ValidationHelperService.getErrorMessage(_form.controls.circuitSets)
                        }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-[10rem]" subscriptSizing="dynamic">
                    <mat-label>Rest time</mat-label>
                    <input type="number" matInput [formControl]="_form.controls.restTime">
                    <mat-error *ngIf="ValidationHelperService.isInvalid(_form.controls.restTime)">
                        {{
                            ValidationHelperService.getErrorMessage(_form.controls.restTime)
                        }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-col gap-5 my-5 example-list" cdkDropList (cdkDropListDropped)="drop($event)">
            <app-draggable-exercise-config class="example-box" cdkDrag [config]="config" *ngFor="let config of selectedConfigs"></app-draggable-exercise-config>
        </div>
    </div>

</form>
