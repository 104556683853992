<div class="flex flex-col gap-3 max-w-[1200px] h-[90vh] p-[3rem] mx-auto">
    <div class="flex justify-between items-center border-b py-3">
        <div class="flex items-center justify-center gap-3">
            <h2 class="text-4xl !m-0">Create workout</h2>
        </div>
    </div>
    <div *ngIf="!isLoading" class="flex flex-col gap-5 py-8 w-full h-[calc(100%-1200px)]">
        <app-workout-form [_form]="workoutForm" (_submit)="createWorkout($event)" (_cancel)="goToWorkouts()"></app-workout-form>
    </div>
    <div *ngIf="isLoading" class="flex justify-center items-center w-full h-[600px]">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>
