import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {IRegularExerciseConfig, IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {EExerciseType} from "../../../shared/enums/EExerciseType";
import {EExerciseStartType} from "../../../shared/enums/EExerciseStartType";
import {EExerciseJumpType} from "../../../shared/enums/EExerciseJumpType";
import {IExerciseConfig} from "../../../features/exercises/exercise-form/exercise-form.component";

export class RegularExerciseHelper {
  static currentForm(
    formGroup: FormGroup<IRegularExerciseForm>,
    type: FormControl,
  ) {
    switch (type.value) {
      case 0:
        return formGroup.controls.config.controls.countForm;
      case 1:
        return formGroup.controls.config.controls.reactForm;
      case 2:
        return formGroup.controls.config.controls.sequenceForm;
      case 3:
        return formGroup.controls.config.controls.agilityForm;
      case 4:
        return formGroup.controls.config.controls.verticalForm;
      default:
        type.setValue(0);
        return formGroup.controls.config.controls.countForm;
    }
  }

  static isFormValid(
    formGroup: FormGroup<IRegularExerciseForm>,
    type: FormControl,
  ): boolean {
    let isValid = RegularExerciseHelper.currentForm(
      formGroup,
      formGroup.controls.type,
    ).valid;
    if (type.value !== EExerciseType.Vertical)
      isValid =
        isValid && formGroup.controls.config.controls.countdownForm.valid;
    isValid =
      isValid &&
      formGroup.controls.name.valid &&
      formGroup.controls.config.controls.difficulty.valid &&
      formGroup.controls.category.valid;
    return isValid;
  }

  static createPayload(formGroup: FormGroup<IRegularExerciseForm>) {
    return {
      videoUrl: formGroup.controls.video?.controls.fileUrl.value,
      imageUrl: formGroup.controls.imgThumb?.value,
      thumbnailUrl: formGroup.controls.imgThumb?.value,
      name: formGroup.controls.name.value,
      categoryId: formGroup.controls.category.value.id,
      type: formGroup.controls.type.value,
      isFavorite: false,
      bodyPart: formGroup.controls.bodyPart.value,
      config: RegularExerciseHelper.configPayload(formGroup),
    };
  }

  static configPayload(formGroup: FormGroup<IRegularExerciseForm>): IExerciseConfig {
    return this.getConfigRaw(formGroup.controls.type.value, formGroup.controls.config);
  }

  public static getConfigRaw(exerciseType: EExerciseType, config: FormGroup<IRegularExerciseConfig>): IExerciseConfig {
    const countdownForm = config.controls.countdownForm;
    const countForm = config.controls.countForm;
    const reactForm = config.controls.reactForm;
    const sequenceForm = config.controls.sequenceForm;
    const agilityForm = config.controls.agilityForm;
    const verticalForm = config.controls.verticalForm;

    const countdowns = {
      countdownEndType: countdownForm.controls.endType.value,
      countdownEnd: countdownForm.controls.endValue.value,
      countdownStart: countdownForm.controls.startValue.value,
    };

    if (
        config.controls.countdownForm.controls.startType
            .value === EExerciseStartType.RANDOM
    )
      delete countdowns.countdownStart;

    let configPayload: any = {
      difficulty: config.controls.difficulty.value,
    };

    switch (exerciseType) {
      case 0:
        configPayload = {
          ...configPayload,
          ...countdowns,
          sumType: countForm.controls.countType.value,
        };
        break;

      case 1:
        configPayload = {
          ...configPayload,
          ...countdowns,
          timerDelay: reactForm.controls.delay.value,
          timerPrompt: reactForm.controls.timerPrompt.value === 0 ? null : reactForm.controls.timerPrompt.value,
          reactTo: RegularExerciseHelper.getReactArray(
              reactForm.controls.reactTo,
          ),
          dontReactTo: RegularExerciseHelper.getReactArray(
              reactForm.controls.dontReactTo,
          ),
          activeSensors: RegularExerciseHelper.getreactSensorsArray(
              reactForm.controls.activeSensors,
          ),
          neurocognitiveType: reactForm.controls.neurocognitiveType.value,
          isolatedReact: reactForm.controls.isolatedReact.value,
          liftTime: reactForm.controls.liftTime.value,
          stroopEffectType: reactForm.controls.stroopEffectType.value,
          footFireActivity: reactForm.controls.footFireActivity.value
        };
        break;

      case 2: //sequence
        Object.assign(configPayload, {
          ...countdowns,
          timerDelay: sequenceForm.controls["delay"].value,
          timerPrompt: sequenceForm.controls["timerPrompt"].value === 0 ? null : sequenceForm.controls["timerPrompt"].value,
          sensorsSequence: sequenceForm.controls.sequenceSensors.value,
        });
        break;

      case 3: //agility
        Object.assign(configPayload, {
          ...countdowns,
          timerDelay: agilityForm.controls.delay.value,
          arrowsType: agilityForm.controls.agilityCustomType.value,
          arrows: agilityForm.controls.agilitySensors.value,
          timerPrompt: agilityForm.controls.timerPrompt.value === 0 ? null : agilityForm.controls.timerPrompt.value,
        });
        break;

      case 4: //vertical
        Object.assign(configPayload, {
          numberOfLegs: verticalForm.controls.numberOfLegs.value,
          jumpType: verticalForm.controls.verticalJumpType.value,
          jumpCount: verticalForm.controls.numberOfJumps.value,
        });
        if(verticalForm.controls.verticalJumpType.value === EExerciseJumpType.VERTICAL) {
          Object.assign(configPayload, {
            verticalReact: verticalForm.controls.isVerticalReact.value
          })
          if(verticalForm.controls.isVerticalReact.value)
          Object.assign(configPayload, {
            reactTo: RegularExerciseHelper.getReactArray(
                verticalForm.controls.reactTo,
            ),
            dontReactTo: RegularExerciseHelper.getReactArray(
                verticalForm.controls.dontReactTo,
            ),
          });
        }

        break;

      default:
        break;
    }

    return configPayload;
  }

  static getReactArray(formArray: FormArray<FormControl<boolean>>): number[] {
    return formArray.value
      .map((value, index) => (value ? index : null))
      .filter((value) => value !== null);
  }

  static getreactSensorsArray(
    formArray: FormArray<FormControl<boolean>>,
  ): number[] {
    return formArray.value
      .map((value, index) => (value ? index + 1 : null))
      .filter((value) => value !== null);
  }

  static patchFormConfig(
    formGroup: FormGroup<IRegularExerciseForm>,
    exercise: any): void {
    const selectedConfig = exercise.configs.find(
        (config) => config.id === exercise.defaultConfigId,
    );

    if(selectedConfig?.timerPrompt === null) selectedConfig.timerPrompt = 0;

    formGroup.controls.config.controls.sequenceForm.controls.sequenceSensors.clear();
    selectedConfig?.sensorsSequence?.forEach((val) =>
        formGroup.controls.config.controls.sequenceForm.controls.sequenceSensors.push(
            new FormControl(val),
        ),
    );

    formGroup.controls.config.controls.agilityForm.controls.agilitySensors.clear();
    selectedConfig?.arrows?.forEach((arrow: number) =>
        formGroup.controls.config.controls.agilityForm.controls.agilitySensors.push(
            new FormControl(arrow),
        ),
    );


    const value = {
      config: {
        difficulty: selectedConfig.difficulty,
        countdownForm: {
          endType: selectedConfig.countdownEndType,
          endValue: selectedConfig.countdownEnd,
          startValue: selectedConfig?.countdownStart,
          startType: 1,
        },
        countForm: {
          countType: selectedConfig.sumType,
        },
        reactForm: {
          delay: selectedConfig.timerDelay,
          timerPrompt: selectedConfig.timerPrompt,
          neurocognitiveType: selectedConfig.neurocognitiveType,
          stroopEffectType: selectedConfig.stroopEffectType,
          liftTime: selectedConfig.liftTime,
          footFireActivity: selectedConfig.footFireActivity,
          isolatedReact: selectedConfig.isolatedReact,
          reactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.reactTo?.includes(index);
          }),
          dontReactTo: new Array(6).fill(false).map((value, index) => {
            return selectedConfig?.dontReactTo?.includes(index);
          }),
          activeSensors: new Array(5).fill(false).map((value, index) => {
            return selectedConfig?.activeSensors?.includes(index + 1);
          }),
        },
        sequenceForm: {
          delay: selectedConfig.timerDelay,
          sequenceSensors: selectedConfig.sensorsSequence,
          timerPrompt: selectedConfig.timerPrompt,
        },
        agilityForm: {
          delay: selectedConfig.timerDelay,
          agilityCustomType: selectedConfig.arrowsType,
          agilitySensors: selectedConfig.arrows,
          timerPrompt: selectedConfig.timerPrompt
        },
        verticalForm: {
          numberOfLegs: selectedConfig.numberOfLegs,
          verticalJumpType: selectedConfig.jumpType,
          numberOfJumps: selectedConfig.jumpCount,
          isVerticalReact: selectedConfig.reactTo?.length > 0 ||  selectedConfig.dontReactTo?.length > 0,
          reactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.reactTo?.includes(index);
          }),
          dontReactTo: new Array(6).fill(false).map((value, index) => {
            return selectedConfig?.dontReactTo?.includes(index);
          }),
        },
      },
    };
    if (!selectedConfig?.countdownStart) {
      delete value.config.countdownForm.startValue;
      value.config.countdownForm.startType = EExerciseStartType.RANDOM;
    }

    formGroup.patchValue(value);
  }

  static patchFormValue(
    formGroup: FormGroup<IRegularExerciseForm>,
    exercise,
  ): void {
    const selectedConfig = exercise.configs.find(
      (config) => config.id === exercise.defaultConfigId,
    );
    selectedConfig?.sensorsSequence?.forEach((val) =>
      formGroup.controls.config.controls.sequenceForm.controls.sequenceSensors.push(
        new FormControl(val),
      ),
    );
    if(selectedConfig?.timerPrompt === null) selectedConfig.timerPrompt = 0;
    const value = {
      category: {
        id: exercise.categoryId,
        name: null,
      },
      name: exercise.name,
      type: exercise.type,
      video: { fileUrl: exercise.videoUrl, file: null},
      imgThumb: exercise.thumbnailUrl,
      bodyPart: exercise.bodyPart,
      config: {
        difficulty: selectedConfig.difficulty,
        countdownForm: {
          endType: selectedConfig.countdownEndType,
          endValue: selectedConfig.countdownEnd,
          startValue: selectedConfig?.countdownStart,
          startType: 1,
        },
        countForm: {
          countType: selectedConfig.sumType,
        },
        reactForm: {
          delay: selectedConfig.timerDelay,
          timerPrompt: selectedConfig.timerPrompt,
          stroopEffectType: selectedConfig.stroopEffectType,
          liftTime:  selectedConfig.liftTime,
          footFireActivity:  selectedConfig.footFireActivity,
          neurocognitiveType: selectedConfig.neurocognitiveType,
          isolatedReact: selectedConfig.isolatedReact,
          reactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.reactTo?.includes(index);
          }),
          dontReactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.dontReactTo?.includes(index);
          }),
          activeSensors: new Array(5).fill(false).map((value, index) => {
            return selectedConfig?.activeSensors?.includes(index + 1);
          }),
        },
        sequenceForm: {
          delay: selectedConfig.timerDelay,
          sequenceSensors: selectedConfig.sensorsSequence,
          timerPrompt: selectedConfig.timerPrompt,
        },
        agilityForm: {
          delay: selectedConfig.timerDelay,
          agilityCustomType: selectedConfig.arrowsType,
          agilitySensors: selectedConfig.arrows,
          timerPrompt: selectedConfig.timerPrompt
        },
        verticalForm: {
          numberOfLegs: selectedConfig.numberOfLegs,
          verticalJumpType: selectedConfig.jumpType,
          numberOfJumps: selectedConfig.jumpCount,
          isVerticalReact: selectedConfig.verticalReact,
          reactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.reactTo?.includes(index);
          }),
          dontReactTo: new Array(8).fill(false).map((value, index) => {
            return selectedConfig?.dontReactTo?.includes(index);
          }),
        },
      },
    };
    if (!selectedConfig?.countdownStart) {
      delete value.config.countdownForm.startValue;
      value.config.countdownForm.startType = EExerciseStartType.RANDOM;
    }

    selectedConfig?.arrows?.forEach((arrow: number) =>
      formGroup.controls.config.controls.agilityForm.controls.agilitySensors.push(
        new FormControl(arrow),
      ),
    );

    formGroup.patchValue(value);
  }
}
