import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {IPairedExerciseForm} from "../../../shared/interfaces/forms/exercises/IPairedExerciseForm";
import {RegularExerciseFormFactory} from "./RegularExerciseFormFactory";
import {IReactForm} from "../../../shared/interfaces/forms/exercises/IReactForm";
import {ExerciseValidationService} from "../../helpers/exercise-validation.service";
import {ISequenceForm} from "../../../shared/interfaces/forms/exercises/ISequenceForm";
import {IRegularExerciseConfig, IRegularExerciseForm,} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {FormHelper} from "../../helpers/form-helper";
import {ICategory} from "../../../shared/interfaces/ICategory";
import {IVerticalForm} from "../../../shared/interfaces/forms/exercises/IVerticalForm";
import {ICountdownForm} from "../../../shared/interfaces/forms/exercises/ICountdownForm";
import {ICountForm} from "../../../shared/interfaces/forms/exercises/ICountForm";
import {EBodyPart} from "../../../shared/enums/EBodyPart";
import {EStroopEffectType} from "../../../shared/enums/EStroopEffectType";

export class PairedExerciseFormFactory {
  static create(): FormGroup<IPairedExerciseForm> {
    const categoryControl = new FormControl(
      null,
      [Validators.required],
    );

    const typeControl = new FormControl(0, [Validators.required]);
    const firstExerciseForm = RegularExerciseFormFactory.create();
    firstExerciseForm.controls.category = FormHelper.clone(
      categoryControl,
    ) as FormControl<ICategory>;
    firstExerciseForm.controls.type = FormHelper.clone(
      typeControl,
    ) as FormControl<number>;

    const secondExerciseForm = PairedExerciseFormFactory.siblingForm(
      firstExerciseForm,
      typeControl,
      categoryControl,
    );

    return new FormGroup<IPairedExerciseForm>({
      name: new FormControl("", [Validators.required]),
      category: categoryControl,
      type: typeControl,
      firstExercise: firstExerciseForm,
      secondExercise: secondExerciseForm,
    });
  }

  static siblingForm(
    firstExerciseForm: FormGroup<IRegularExerciseForm>,
    typeControl: FormControl<number>,
    categoryControl: FormControl<ICategory>,
  ): FormGroup<IRegularExerciseForm> {
    return new FormGroup<IRegularExerciseForm>({
        bodyPart: new FormControl<EBodyPart>(null),
      active: new FormControl(true, [Validators.required]),
      name: new FormControl("", [Validators.required]),
      category: FormHelper.clone(categoryControl) as FormControl<ICategory>,
      type: FormHelper.clone(typeControl) as FormControl<number>,
      video: new FormGroup({
          file: new FormControl<File>(null),
          fileUrl: new FormControl<string>('')
      }),
      imgThumb: new FormControl(""),
      config: new FormGroup<IRegularExerciseConfig>({
        difficulty: FormHelper.clone(
          firstExerciseForm.controls.config.controls.difficulty,
        ) as FormControl<number>,
        verticalForm: FormHelper.clone(
          firstExerciseForm.controls.config.controls.verticalForm,
        ) as FormGroup<IVerticalForm>,
        countdownForm: FormHelper.clone(
          firstExerciseForm.controls.config.controls.countdownForm,
        ) as FormGroup<ICountdownForm>,
        reactForm: new FormGroup<IReactForm>(
          {
            delay: FormHelper.clone(
              firstExerciseForm.controls.config.controls.reactForm.controls
                .delay,
            ) as FormControl<number>,
              stroopEffectType: FormHelper.clone(
                  firstExerciseForm.controls.config.controls.reactForm.controls
                      .stroopEffectType,
              ) as FormControl<EStroopEffectType>,
              liftTime: FormHelper.clone(
                  firstExerciseForm.controls.config.controls.reactForm.controls
                      .liftTime,
              ) as FormControl<boolean>,
              footFireActivity: FormHelper.clone(
                  firstExerciseForm.controls.config.controls.reactForm.controls
                      .footFireActivity,
              ) as FormControl<boolean>,
              timerPrompt: FormHelper.clone(
              firstExerciseForm.controls.config.controls.reactForm.controls
                .timerPrompt,
            ) as FormControl<number>,
            neurocognitiveType: FormHelper.clone(
              firstExerciseForm.controls.config.controls.reactForm.controls
                .neurocognitiveType,
            ) as FormControl<number>,
            isolatedReact: new FormControl(false, Validators.required),
            reactTo: FormHelper.clone(
              firstExerciseForm.controls.config.controls.reactForm.controls
                .reactTo,
            ) as FormArray<FormControl<boolean>>,
            dontReactTo: FormHelper.clone(
              firstExerciseForm.controls.config.controls.reactForm.controls
                .dontReactTo,
            ) as FormArray<FormControl<boolean>>,
            activeSensors: new FormArray([
              new FormControl(true),
              new FormControl(true),
              new FormControl(true),
              new FormControl(true),
              new FormControl(true),
            ]),
          },
          [
            ExerciseValidationService.neurocognitiveValidation(),
            ExerciseValidationService.activeSensorValidation(),
          ],
        ),
        countForm: FormHelper.clone(
          firstExerciseForm.controls.config.controls.countForm,
        ) as FormGroup<ICountForm>,
        sequenceForm: new FormGroup<ISequenceForm>(
          {
            delay: FormHelper.clone(
              firstExerciseForm.controls.config.controls.sequenceForm.controls
                .delay,
            ) as FormControl<number>,
            timerPrompt: FormHelper.clone(
              firstExerciseForm.controls.config.controls.sequenceForm.controls
                .timerPrompt,
            ) as FormControl<number>,
            sequenceSensors: new FormArray<FormControl<number>>(
              [],
              [
                Validators.required,
                Validators.maxLength(200),
              ],
            ),
          },
          [ExerciseValidationService.sequenceValidation()],
        ),
        agilityForm: new FormGroup(
          {
            delay: FormHelper.clone(
              firstExerciseForm.controls.config.controls.agilityForm.controls
                .delay,
            ) as FormControl<number>,
            agilityCustomType: new FormControl(0, [Validators.required]),
            agilitySensors: new FormArray<FormControl<number>>(
              [],
              [
                Validators.required,
                Validators.maxLength(200),
              ],
            ),
              timerPrompt: FormHelper.clone(
                firstExerciseForm.controls.config.controls.agilityForm.controls
                  .timerPrompt,
              ) as FormControl<number>,
              
          },
          [ExerciseValidationService.arrowValidation()],
        ),
      }),
    });
  }
}
