import {Injectable} from "@angular/core";
import {ISettingsDTO} from "../../shared/DTOs/ISettingsDTO";
import {Subject} from "rxjs";


@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private _settingsValue: ISettingsDTO;
  private _settings = new Subject<ISettingsDTO>();
  public $settings = this._settings.asObservable();

  constructor() {}

  public set settings (settings: ISettingsDTO) {
    this._settings.next(settings);
  }

  public get settings (): ISettingsDTO{
    return this._settingsValue;
  }
}
