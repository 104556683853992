import {Component, Input} from "@angular/core";
import {IExercisesCategoryForm} from "../../workout-form/WorkoutFormFactory";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-exercises-category-multiselect',
  templateUrl: './exercises-category-multiselect.component.html',
  styleUrls: ['./exercises-category-multiselect.component.scss']
})
export class ExercisesCategoryMultiselectComponent {
  @Input() _form: FormGroup<IExercisesCategoryForm>;
}
