<div class="flex flex-col gap-3 p-5 w-full min-w-[600px]">
  <div>
    <h2>{{ data.title }}</h2>
  </div>
  <div>
    <p>{{ data.message }}</p>
  </div>
  <div class="flex justify-end flex-grow">
    <div class="flex justify-center items-center gap-3">
        <button
                type="button"
                mat-flat-button
                (click)="dialogRef.close(false)"
        >
            {{ data.cancelButtonLabel ? data.cancelButtonLabel : "No" }}
        </button>
      <button
        type="button"
        mat-flat-button
        color="warn"
        (click)="dialogRef.close(true)"
      >
        {{
          data.confirmationButtonLabel ? data.confirmationButtonLabel : "Yes"
        }}
      </button>
    </div>
  </div>
</div>
