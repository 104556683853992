import {Component, Input} from "@angular/core";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IFullPairedExerciseForm, IPairedExerciseConfigForm} from "../../../../../workout-form/WorkoutFormFactory";
import {RegularExerciseHelper} from "../../../../../../../core/forms/helpers/RegularExerciseHelper";
import {ExerciseHelper} from "../../../../../../../core/helpers/exercise.helper";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ValidationHelperService} from "../../../../../../../core/helpers/validation-helper.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";

@Component({
  selector: "app-paired-exercise-config-row",
  templateUrl: "./paired-exercise-config-row.component.html",
  styleUrls: ["./paired-exercise-config-row.component.scss"],
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf
  ],
  standalone: true
})
export class PairedExerciseConfigRowComponent {
  @Input() configForm: FormGroup<IPairedExerciseConfigForm>;
  @Input() exerciseForm: FormGroup<IFullPairedExerciseForm>;

  constructor(private exerciseHelper: ExerciseHelper) {}

  getFirstConfigTitle(): string {
    const firstExerciseConfigForm = this.exerciseForm.controls.firstExercise.controls.configs.controls.find(config => config.controls.id.value === this.configForm.controls.firstConfigId.value);
    if(!firstExerciseConfigForm) return '-';
    const firstConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm?.controls.type.value, firstExerciseConfigForm);

    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, firstConfig);
  }

  getSecondConfigTitle(): string {
    const secondExerciseConfigForm = this.exerciseForm.controls.secondExercise.controls.configs.controls.find(config => config.controls.id.value === this.configForm.controls.secondConfigId.value);
    if(!secondExerciseConfigForm) return "-";

    const secondConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm?.controls.type.value, secondExerciseConfigForm);
    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, secondConfig);
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
