import {Component} from "@angular/core";
import {SportFormFactory} from "../sport-form/SportFormFactory";
import {SportsControllerService} from "../../../core/api/controllers/sports-controller.service";
import {ISport} from "../../../shared/interfaces/ISport";
import {MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../../core/services/notification.service";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";

@Component({
  selector: 'app-create-injury-dialog',
  templateUrl: './create-sport-dialog.component.html',
  styleUrls: ['./create-sport-dialog.component.scss']
})
export class CreateSportDialogComponent {
  isLoading = false;
  sportForm = SportFormFactory.create();

  constructor(
      private sportController: SportsControllerService,
      private matDialogRef: MatDialogRef<CreateSportDialogComponent>,
      private notificationService: NotificationService
  ) {}


  onSubmit(): void {
    this.sportForm.markAllAsTouched();
    if(this.sportForm.valid) this.createSport();
  }

  createSport(): void {
    this.isLoading = true;
    this.sportController.createSport(this.createPayload() as ISport).subscribe({
      next: () => {
        this.notificationService.success("The sport has been created succesfully!");
        this.matDialogRef.close(true);
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error);
        this.isLoading = false;
      }
    })
  }

  createPayload(): Partial<ISport> {
    return {
      name: this.sportForm.controls.name.value
    }
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
