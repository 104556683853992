<div class="flex flex-col gap-3 h-full">
    <div class="flex flex-col gap-5">
        <div class="w-full p-3 font-medium text-xl color-primary bg-gray-400 sticky top-0 z-50 mt-3">
            {{_form.controls.name.value}}
        </div>
        <div class="flex flex-col gap-5" *ngIf="_form.controls.exercises.controls.length > 0">
            <app-exercise-row *ngFor="let exercise of _form.controls.exercises.controls" [_form]="exercise"></app-exercise-row>
        </div>
        <div *ngIf="_form.controls.exercises.controls.length === 0" class="flex justify-center items-center w-full h-[100px]">
            <p class="!m-0 text-gray-500 text-medium">This category has no exercises.</p>
        </div>
    </div>
</div>
