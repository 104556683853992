<div class="p-5 flex flex-col gap-3">
    <h2 class="text-2xl">Create injury</h2>
    <form (ngSubmit)="onSubmit()" [formGroup]="injuryForm" class="flex flex-col gap-3">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Injury name</mat-label>
            <input type="text" matInput [formControl]="injuryForm.controls.name" />
            <mat-error *ngIf="ValidationHelperService.isInvalid(injuryForm.controls.name)">
                {{
                    ValidationHelperService.getErrorMessage(injuryForm.controls.name)
                }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Injury location</mat-label>
            <mat-select [formControl]="injuryForm.controls.injuryLocation">
                <mat-option [value]="EInjuryLocation.Head">Head</mat-option>
                <mat-option [value]="EInjuryLocation.Hip">Hip</mat-option>
                <mat-option [value]="EInjuryLocation.Thigh">Thigh</mat-option>
                <mat-option [value]="EInjuryLocation.Knee">Knee</mat-option>
                <mat-option [value]="EInjuryLocation.Leg">Leg</mat-option>
                <mat-option [value]="EInjuryLocation.Ankle">Ankle</mat-option>
                <mat-option [value]="EInjuryLocation.Foot">Foot</mat-option>
                <mat-option [value]="EInjuryLocation.Shoulder">Shoulder</mat-option>
                <mat-option [value]="EInjuryLocation.Elbow">Elbow</mat-option>
                <mat-option [value]="EInjuryLocation.Wrist">Wrist</mat-option>
                <mat-option [value]="EInjuryLocation.Hand">Hand</mat-option>
                <mat-option [value]="EInjuryLocation.Neck">Neck</mat-option>
                <mat-option [value]="EInjuryLocation.Back">Back</mat-option>
                <mat-option [value]="EInjuryLocation.Neuro">Neuro</mat-option>
            </mat-select>
            <mat-error *ngIf="ValidationHelperService.isInvalid(injuryForm.controls.injuryLocation)">
                {{
                    ValidationHelperService.getErrorMessage(injuryForm.controls.injuryLocation)
                }}
            </mat-error>
        </mat-form-field>

        <div class="flex justify-end">
            <button mat-flat-button color="primary">
                Save
            </button>
        </div>
    </form>
</div>
