import {Component, Input} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {EExerciseJumpType} from "../../../../shared/enums/EExerciseJumpType";
import {IVerticalForm} from "../../../../shared/interfaces/forms/exercises/IVerticalForm";

@Component({
  selector: "app-vertical-exercise-form",
  templateUrl: "./vertical-exercise-form.component.html",
  styleUrls: ["./vertical-exercise-form.component.scss"],
})
export class VerticalExerciseFormComponent {
  @Input() _formGroup: FormGroup<IVerticalForm>;
  protected readonly ValidationHelperService = ValidationHelperService;

  setReact(color: number, type: number) {
    this.toggleReact(color, type);

    if (type !== 2 && this.getSensorValue(color, 2)) {
      this.toggleReact(color, 2);
    } else if (type === 2 && this.getSensorValue(color, 1)) {
      this.toggleReact(color, 1);
    }
  }


  getSensorValue(color, type): boolean {
    return type !== 2 ? this.react[color] : this.dontReact[color];
  }

  toggleReact(color: number, type: number) {
    const typeKey = type !== 2 ? "reactTo" : "dontReactTo";
    if (typeKey === "reactTo") {
      this._formGroup.controls.reactTo.controls[color].setValue(
          !this._formGroup.get(typeKey).value[color],
      );
    } else if (typeKey === "dontReactTo") {
      this._formGroup.controls.dontReactTo.controls[color].setValue(
          !this._formGroup.get(typeKey).value[color],
      );
    }
  }

  get verticalJumpType(): FormControl {
    return this._formGroup.get("verticalJumpType") as FormControl;
  }

  get numberOfLegs(): FormControl {
    return this._formGroup.get("numberOfLegs") as FormControl;
  }

  get numberOfJumps(): FormControl {
    return this._formGroup.get("numberOfJumps") as FormControl;
  }

  get react(): boolean[] {
    return this._formGroup.get("reactTo").value;
  }

  get dontReact(): boolean[] {
    return this._formGroup.get("dontReactTo").value;
  }
    protected readonly EExerciseJumpType = EExerciseJumpType;
}
