import {IFullPairedExercise, IFullRegularExercise} from "../../features/workouts/workout-form/WorkoutFormFactory";

export interface ICategory {
  id: string;
  name: string;
  exercises?: IExercise[];
}

export interface IExercise {
  regularExercise: IFullRegularExercise;
  pairedExercise: IFullPairedExercise;
}

export class Category {
  id: string;
  name: string;

  constructor(category: ICategory) {
    this.id = category.id;
    this.name = category.name;
  }
}
