<form class="flex flex-col gap-3 h-full">
    <mat-form-field appearance="outline" subscriptSizing="fixed" class="h-[80px]">
        <mat-label>Name</mat-label>
        <input type="text" matInput [formControl]="_form.controls.name">
        <mat-error *ngIf="ValidationHelperService.isInvalid(_form.controls.name)">
            {{
                ValidationHelperService.getErrorMessage(_form.controls.name)
            }}
        </mat-error>
    </mat-form-field>
    <div class="h-[calc(100%-80px)]">
        <div class="h-full flex flex-col gap-3">
            <div class="w-full h-[56px] bg-white z-50">
                <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                    <mat-label>Search category or exercise</mat-label>
                    <input type="text" matInput>
                </mat-form-field>
            </div>
            <div class="h-[calc(100%-56px)] overflow-y-auto" *ngIf="!isLoading">
                <div *ngFor="let exerciseCategoryForm of _form.controls.exerciseCategories.controls">
                    <app-exercises-category-multiselect [_form]="exerciseCategoryForm"></app-exercises-category-multiselect>
                </div>
            </div>
            <div *ngIf="isLoading" class="w-full h-[400px] flex justify-center items-center">
                <mat-spinner diameter="30"></mat-spinner>
            </div>
        </div>

    </div>

</form>
