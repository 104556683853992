import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  private static findErrorMessage(error): string {
    const err = error.error;
    if (err) {
      if (typeof err === "string") {
        return err;
      }
      if (err.error) {
        return err.error;
      }
      if (err.message) {
        return err.message;
      }
      if (err.error_description) {
        return err.error_description;
      }
    }
    return "The server is currently busy. Please try again later.";
  }

  success(message: any) {
    this.snackBar.open(message, "Close", {
      duration: 2000,
    });
  }

  warn(message: any) {
    if (typeof message === "string") {
      this.snackBar.open(message, "Close", {
        duration: 2000,
      });
      return;
    }

    this.snackBar.open(NotificationService.findErrorMessage(message), "Close", {
      duration: 2000,
    });
  }

  error(message: any) {
    if (typeof message === "string") {
      this.snackBar.open(message, "Close", {
        duration: 5000,
      });
      return;
    }

    if (message?.error?.validationErrors?.at(0)) {
      this.snackBar.open(message.error?.validationErrors[0], "Close", {
        duration: 2000,
      });
      return;
    }

    if (message?.error?.error) {
      this.snackBar.open(message?.error?.error, "Close", {
        duration: 2000,
      });
      return;
    }

    if (message.error !== "invalid_token") {
      this.snackBar.open(
        NotificationService.findErrorMessage(message),
        "Close",
        {
          duration: 2000,
        },
      );
    }
  }
}
