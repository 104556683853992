import {Component} from "@angular/core";
import {WorkoutFormFactory} from "../workout-form/WorkoutFormFactory";
import {IWorkout} from "../../../shared/interfaces/IWorkout";
import {Router} from "@angular/router";
import {WorkoutsControllerService} from "../../../core/api/controllers/workouts/workouts-controller.service";
import {NotificationService} from "../../../core/services/notification.service";

@Component({
  selector: 'app-create-workout',
  templateUrl: './create-workout.component.html',
  styleUrls: ['./create-workout.component.scss']
})
export class CreateWorkoutComponent {
  isLoading = false;
  workoutForm = WorkoutFormFactory.create();

  constructor(
      private router: Router,
      private workoutsController: WorkoutsControllerService,
      private notificationService: NotificationService
      ) {}

  createWorkout(newWorkout: IWorkout): void {
    this.isLoading = true;
    this.workoutsController.createWorkout(newWorkout).subscribe({
      next: () => {
        this.notificationService.success("Workout created succesfully!")
        this.router.navigateByUrl("/workouts");
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notificationService.error(error);
        this.isLoading = false;
      }
    })
  }


  goToWorkouts(): void {
    this.router.navigateByUrl("/workouts");
  }

}
