import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IRegularExerciseConfigForm} from "../../../../../core/forms/factory/RegularExerciseFormFactory";
import {IFullRegularExerciseForm} from "../../../workout-form/WorkoutFormFactory";
import {ExerciseHelper} from "../../../../../core/helpers/exercise.helper";
import {RegularExerciseHelper} from "../../../../../core/forms/helpers/RegularExerciseHelper";

@Component({
  selector: 'app-regular-exercise-config',
  templateUrl: './regular-exercise-config.component.html',
  styleUrls: ['./regular-exercise-config.component.scss'],
  standalone: true
})
export class RegularExerciseConfigComponent {
  @Input() exerciseForm: FormGroup<IFullRegularExerciseForm>;
  @Input() _form: FormGroup<IRegularExerciseConfigForm>;

  constructor(private exerciseHelper: ExerciseHelper) {
  }

  getConfigTitle(): string {
    const rawConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm?.controls.type.value, this._form);
    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, rawConfig);
  }
}
