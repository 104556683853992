import {Component, Input, OnInit} from "@angular/core";
import {ExercisesCategoryFormFactory, ExercisesCategoryFormHelper, IPlaylistForm} from "../workout-form/WorkoutFormFactory";
import {FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {ICategory} from "../../../shared/interfaces/ICategory";
import {NotificationService} from "../../../core/services/notification.service";

@Component({
  selector: 'app-playlist-form',
  templateUrl: './playlist-form.component.html',
  styleUrls: ['./playlist-form.component.scss'],
  host: {
    class: 'h-[calc(100%-100px)]'
  }
})
export class PlaylistFormComponent implements OnInit{
  isLoading = false;
  @Input() _form: FormGroup<IPlaylistForm>;
  exerciseCategories: ICategory[] = [];

  constructor(
      private exercisesController: ExercisesControllerService,
      private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if(this._form.controls.exerciseCategories.length === 0) this.getExerciseCategories();
  }

  getExerciseCategories(): void {
    this.isLoading = true;
    this.exercisesController.getCategories().subscribe({
      next: (res) => {
        this.exerciseCategories = res;
        this.exerciseCategories.forEach(category => {
          const newCategoryForm = ExercisesCategoryFormFactory.create();
          ExercisesCategoryFormHelper.patchFormValue(newCategoryForm, category);
          this._form.controls.exerciseCategories.push(newCategoryForm);
        })
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      }
    });
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
