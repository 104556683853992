import {Injectable} from "@angular/core";

export enum VideoToFramesMethod {
    fps,
    totalFrames
}

@Injectable({
    providedIn: "root",
})
export class VideoHelperService {


    public getFrames(
        videoUrl: string,
        amount: number,
        type: VideoToFramesMethod = VideoToFramesMethod.fps
    ): Promise<File[]> {
        return new Promise(
            (
                resolve: (frames: File[]) => void,
                reject: (error: string) => void
            ) => {
                let frames: File[] = [];
                let canvas: HTMLCanvasElement = document.createElement("canvas");
                let context: CanvasRenderingContext2D = canvas.getContext("2d");
                let duration: number;

                let video = document.createElement("video");
                video.preload = "auto";
                let that = this;
                video.addEventListener("loadeddata", async function () {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    duration = video.duration;

                    let totalFrames: number = amount;
                    if (type === VideoToFramesMethod.fps) {
                        totalFrames = duration * amount;
                    }
                    for (let time = 0; time < duration; time += duration / totalFrames) {
                        frames.push(await that.getVideoFrame(video, context, canvas, time));
                    }
                    resolve(frames);
                });
                video.src = videoUrl;
                video.load();
            }
        );
    }

    private getVideoFrame(
        video: HTMLVideoElement,
        context: CanvasRenderingContext2D,
        canvas: HTMLCanvasElement,
        time: number
    ): Promise<File> {
        return new Promise(
            (resolve: (frame: File) => void, reject: (error: string) => void) => {
                let eventCallback = () => {
                    video.removeEventListener("seeked", eventCallback);
                    this.storeFrame(video, context, canvas, resolve);
                };
                video.addEventListener("seeked", eventCallback);
                video.currentTime = time;
            }
        );
    }

    private storeFrame(
        video: HTMLVideoElement,
        context: CanvasRenderingContext2D,
        canvas: HTMLCanvasElement,
        resolve: (frame: File) => void
    ) {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(this.dataURLtoFile(canvas.toDataURL(), 'frame'));
    }

    private dataURLtoFile(dataurl: string, filename: string): File {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
}
