import {Component, Input} from "@angular/core";
import {EExerciseType} from "../../../../../shared/enums/EExerciseType";

@Component({
  selector: 'app-exercise-type-badge',
  templateUrl: './exercise-type-badge.component.html',
  styleUrls: ['./exercise-type-badge.component.scss']
})
export class ExerciseTypeBadgeComponent {
  @Input() type: EExerciseType;

  getTypeString(): string {
    switch(this.type) {
      case EExerciseType.Agility: return "Agility";
      case EExerciseType.Count: return "Count";
      case EExerciseType.Vertical: return "Vertical";
      case EExerciseType.Sequence: return "Sequence";
      case EExerciseType.React: return "React";
    }
  }
}
