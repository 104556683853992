import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {RegisterComponent} from "./features/register/register.component";
import {AthletesComponent} from "./features/athletes/athletes.component";
import {LoginComponent} from "./features/login/login.component";
import {CodeGuard} from "./core/guards/code.guard";
import {UserGuard} from "./core/guards/user.guard";
import {NotUserGuard} from "./core/guards/notuser.guard";
import {PlaylistsComponent} from "./features/playlists/playlists.component";
import {ExercisesComponent} from "./features/exercises/exercises.component";
import {WorkoutsComponent} from "./features/workouts/workouts.component";
import {SaveAthleteComponent} from "./features/athletes/save-athlete.component";
import {SaveWorkoutComponent} from "./features/workouts/save-workout.component";
import {SaveExerciseComponent} from "./features/exercises/save-exercise/save-exercise.component";
import {DashboardComponent} from "./features/dashboard/dashboard.component";
import {ResultsComponent} from "./features/results/results.component";
import {ReportsComponent} from "./features/reports/reports.component";
import {ActivityDataComponent} from "./features/activity-data/activity-data.component";
import {ResetPasswordComponent} from "./features/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./features/forgot-password/forgot-password.component";
import {PrivacyPolicyComponent} from "./features/privacy-policy/privacy-policy.component";
import {TermsAndConditionsComponent} from "./features/terms-and-conditions/terms-and-conditions.component";
import {SelectLocationsComponent} from "./features/select-locations/select-locations.component";
import {OverviewComponent} from "./features/overview/overview.component";
import {SyncWorkoutComponent} from "./features/workouts/sync-workout/sync-workout.component";
import {SavePairedExerciseComponent} from "./features/exercises/save-paired-exercise/save-paired-exercise.component";
import {AgilityRatingComponent} from "./features/athletes/agility-rating/agility-rating.component";
import {SyncExerciseComponent} from "./features/exercises/sync-exercise/sync-exercise.component";
import {UnsavedChangesGuard} from "./core/guards/can-deactivate.guard";
import {NoLocationWhitelistedComponent} from "./features/no-location-whitelisted/no-location-whitelisted.component";
import {LoggedOutGuard} from "./core/guards/logged-out.guard";
import {MaintenanceComponent} from "./shared/components/maintenance/maintenance.component";
import {CreateWorkoutComponent} from "./features/workouts/create-workout/create-workout.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "dashboard" },
  },
  {
    path: "sports",
    loadChildren: () =>
        import("./features/sports/sports.module").then(
            (m) => m.SportsModule,
        )
  },
  {
    path: "injuries",
    loadChildren: () =>
        import("./features/injuries/injuries.module").then(
            (m) => m.InjuriesModule,
        )
  },
  {
    path: "overview",
    component: OverviewComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "admin" },
  },
  {
    path: "locations",
    component: SelectLocationsComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "locations" },
  },
  {
    path: "no-location-allowed",
    canActivate: [CodeGuard, UserGuard],
    component: NoLocationWhitelistedComponent,
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [NotUserGuard, LoggedOutGuard],
    data: { name: "login" },
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [LoggedOutGuard],
    data: { name: "register" },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [LoggedOutGuard],
    data: { name: "forgot-password" },
  },
  {
    path: "reset-password/:token",
    component: ResetPasswordComponent,
    canActivate: [LoggedOutGuard],
    data: { name: "reset-password" },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./features/settings/settings.module").then(
        (m) => m.SettingsModule,
      ),
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "settings" },
  },
  {
    path: "athletes",
    component: AthletesComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "athletes" },
  },
  {
    path: "athletes/:id",
    component: AgilityRatingComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "agility" },
  },
  {
    path: "patients",
    component: AthletesComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "patients" },
  },
  {
    path: "save-athlete",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-athlete" },
  },
  {
    path: "save-patient",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-patient" },
  },
  {
    path: "save-athlete/:id",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-athlete" },
  },
  {
    path: "save-patient/:id",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-patient" },
  },
  {
    path: "exercises",
    component: ExercisesComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "exercises" },
  },
  {
    path: "save-paired-exercise",
    component: SavePairedExerciseComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-paired-exercise" },
  },
  {
    path: "save-exercise",
    component: SaveExerciseComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-exercise" },
  },
  {
    path: "save-exercise/:id",
    component: SaveExerciseComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-exercise" },
  },
  {
    path: "sync-exercise",
    component: SyncExerciseComponent,
    canActivate: [CodeGuard, UserGuard, ],
  },

  {
    path: "playlists",
    component: PlaylistsComponent,
    canDeactivate: [UnsavedChangesGuard, ],
    canActivate: [CodeGuard, UserGuard],
    data: { name: "playlists" },
  },
  {
    path: "results",
    component: ResultsComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "results" },
  },
  {
    path: "workouts",
    component: WorkoutsComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "workouts" },
  },
  {
    path: "protocols",
    component: WorkoutsComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "protocols" },
  },
  {
    path: "workouts/sync-workout",
    component: SyncWorkoutComponent,
    canActivate: [CodeGuard, UserGuard, ],
  },
  {
    path: "workouts/create",
    component: CreateWorkoutComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "create-workout" },
  },
  {
    path: "save-protocol",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-protocol" },
  },
  {
    path: "workouts/:id",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-protocol" },
  },
  {
    path: "save-protocol/:id",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "save-protocol" },
  },
  {
    path: "reports",
    component: ReportsComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "activity-data" },
  },
  {
    path: "activity-data",
    component: ActivityDataComponent,
    canActivate: [CodeGuard, UserGuard, ],
    data: { name: "locations" },
  },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent },
  {
    path: "maintenance",
    component: MaintenanceComponent,
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
