<div class="app-container">
  <div
    class="navbar fixed-top bg-white p-4 shadow"
    *ngIf="fullyLoggedIn() && !blankPage"
  >
    <div class="d-flex justify-content-between w-100">
      <a class="d-flex align-items-center">
        <div class="pr-4 mr-4 border-right hamburger-menu">
          <i
            class="bars icon side-menu-icon pointer"
            id="hamburger"
            (click)="hidden = !hidden"
          ></i>
        </div>
        <div class="">
          <img
            src="assets/images/qb_logo_black.svg"
            id="quickboard_logo"
            class="pointer"
            routerLink="/"
            width="136"
            height="30"
          />
        </div>
      </a>

      <div class="d-flex align-items-center">
        <div class="no-outline" [matMenuTriggerFor]="menu">
          <div class="d-inline-block pointer account-text">
            {{ name }}
            <i
              class="angle down icon flex large-text grayish"
              style="float: right; margin-left: 5px"
            ></i>
          </div>
          <div class="subtext location-text">
            Location: {{ currentLocation }}
          </div>

          <mat-menu #menu="matMenu">
            <div
              *ngIf="isPaymentAdmin()"
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 border-bottom text-center"
              routerLink="/settings"
            >
              <span>My Account</span>
            </div>

            <div
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 border-bottom text-center"
              *ngIf="!isPaymentAdmin() && fullyLoggedIn()"
              (click)="changeInfo()"
            >
              <span>Change account information</span>
            </div>

            <div
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 border-bottom text-center"
              *ngIf="!isPaymentAdmin() && fullyLoggedIn()"
              (click)="openPasswordModal()"
            >
              <span>Change password</span>
            </div>

            <div
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 border-bottom text-center"
              *ngIf="!isPaymentAdmin() && fullyLoggedIn()"
            >
              <span (click)="fileInput.click()" class="btn">Upload Photo</span>
              <i class="camera icon"></i>
              <span class="image-upload">
                <input
                  type="file"
                  #fileInput
                  name="file"
                  (ngModel)="(profilePicture)"
                  ng2FileSelect
                  [uploader]="imageUploader"
                  (click)="
                    image = null;
                    imagePreviewUrl = null;
                    imageUploader.clearQueue()
                  "
                  accept="image/*"
                />
              </span>
            </div>

            <div
              *ngIf="isPaymentAdmin() && hasMultipleLocations"
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 border-bottom text-center"
              routerLink="/locations"
            >
              <span>Switch Locations</span>
            </div>

            <div
              class="menu-item pointer d-block pt-3 pb-3 pr-5 pl-5 text-center"
              (click)="logout()"
            >
              <span>Logout</span>
            </div>
          </mat-menu>
        </div>

        <img
          class="rounded-circle mr-2 border border-light"
          *ngIf="isPaymentAdmin()"
          routerLink="/settings"
          src="{{ profilePicture || 'assets/images/no_avatar.png' }}"
          width="40"
          height="40"
        />
        <img
          class="rounded-circle mr-2 border border-light"
          *ngIf="
            !isPaymentAdmin() && fullyLoggedIn() && imagePreviewUrl == null
          "
          src="{{ profilePicture || 'assets/images/no_avatar.png' }}"
          width="40"
          height="40"
        />

        <img
          class="rounded-circle mr-2 border border-light"
          *ngIf="
            !isPaymentAdmin() && fullyLoggedIn() && imagePreviewUrl != null
          "
          [src]="imagePreviewUrl"
          width="40"
          height="40"
        />
      </div>
    </div>
  </div>

  <div class="main">
    <div
      id="nav_menu"
      class="left-menu d-flex flex-column shadow"
      *ngIf="fullyLoggedIn() && !blankPage"
      [class]="{ displayed: hidden }"
    >
      <a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="material-icons">home</i>
        <p class="ml-3 align-items-center">Dashboard</p>
      </a>

      <!--<a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/`results`">
        <i class="material-icons">insert_chart</i>
        <p class="ml-3 align-items-center">Results</p>
      </a>-->

      <span
        class="disabled-custom left-menu-item uppercase p-3 align-items-center border-top d-flex"
      >
        <i class="material-icons">insert_chart</i>
        <p class="ml-3 align-items-center">Results</p>
      </span>

      <a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/{{ global?.data?.athleteName }}s"
      >
        <i class="material-icons">group</i>
        <p class="ml-3 align-items-center">
          {{ global?.data?.uppercaseAthleteName }}s
        </p>
      </a>

        <a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/exercises"
      >
        <i class="material-icons">timer</i>
        <p class="ml-3 align-items-center">Exercises</p>
      </a>

<!--      <span
        class="disabled-custom left-menu-item uppercase p-3 align-items-center border-top d-flex"
      >
        <i class="material-icons">timer</i>
        <p class="ml-3 align-items-center">Exercises</p>
      </span>-->

      <!--<a
          class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
          [routerLinkActive]="['current-page']"
          routerLink="/playlists">
          <i class="material-icons">playlist_play</i>
          <p class="ml-3 align-items-center">Playlists</p>
        </a>-->

      <span
        class="disabled-custom left-menu-item uppercase p-3 align-items-center border-top d-flex"
      >
        <i class="material-icons">playlist_play</i>
        <p class="ml-3 align-items-center">Playlists</p>
      </span>

      <!--<a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/{{ global?.data?.workoutName }}s">
        <i class="material-icons">assignment</i>
        <p class="ml-3 align-items-center">{{ global?.data?.uppercaseWorkoutName }}s</p>
      </a>-->

      <span
        class="disabled-custom left-menu-item uppercase p-3 align-items-center border-top d-flex"
      >
        <i class="material-icons">assignment</i>
        <p class="ml-3 align-items-center">
          {{ global?.data?.uppercaseWorkoutName }}s
        </p>
      </span>

      <!-- todo -> add a special type of permission for this section -->
      <!-- <a
        class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
        [routerLinkActive]="['current-page']"
        routerLink="/overview">
        <i class="material-icons">map</i>
        <p class="ml-3 align-items-center">Overview</p>
      </a>-->

      <span
        class="disabled-custom left-menu-item uppercase p-3 align-items-center border-top d-flex"
      >
        <i class="material-icons">map</i>
        <p class="ml-3 align-items-center">Overview</p>
      </span>

        <a
                class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
                [routerLinkActive]="['current-page']"
                routerLink="/sports"
                *ngIf="isSuperAdmin()"
        >
            <i class="material-icons">sports_baseball</i>
            <p class="ml-3 align-items-center">
                Sports
            </p>
        </a>

        <a
                class="left-menu-item uppercase p-3 align-items-center border-top d-flex"
                [routerLinkActive]="['current-page']"
                routerLink="/injuries"
                *ngIf="isSuperAdmin()"
        >
            <i class="material-icons">personal_injury</i>
            <p class="ml-3 align-items-center">
                Injuries
            </p>
        </a>

    </div>

    <div
      class="nav-integration positive-relative"
      [class]="fullyLoggedIn() ? 'content' : 'notLogged'"
    >
      <!-- <div [class]="{'displayed': hidden}" (click)="hidden=true"> -->
      <div [class]="{ displayed: hidden }">
        <ng-container *ngIf="paymentData === undefined">
          <router-outlet></router-outlet>
        </ng-container>
      </div>
    </div>
  </div>
</div>
