<a [routerLink]="'/workouts/' + workout.id" [class.disabled-custom]="isLoading">
    <div class="relative h-[200px]">
        <div class="border-2 rounded-2xl absolute top-0 left-0 w-full h-full hover:opacity-75 transition-all cursor-pointer">
            <div class="absolute left-[1rem] bottom-[1rem] flex flex-col gap-3 ">
                <p class="text-xl !m-0">{{workout.name}}</p>
                <div class="flex items-center gap-2 text-md text-gray-500">
                    <span>{{workout.playlists.length}} playlists</span>
                    <mat-icon class="scale-50">circle</mat-icon>
                    <span>
                {{ getExercisesCount() }} exercises
            </span>
                </div>
            </div>
        </div>
        <div class="absolute top-[1rem] right-[1rem] flex justify-center items-center gap-3">
            <button
                    matRipple
                    class="flex justify-center items-center !rounded-full p-2 !bg-[#F5DC11]"
                    [matMenuTriggerFor]="menu"
                    (click)="$event.preventDefault(); $event.stopPropagation();"
            >
                <div class="flex justify-center items-center">
                    <mat-icon class="scale-75">more_horizontal</mat-icon>
                </div>
            </button>
            <mat-menu class="menu pushed-menu" #menu="matMenu">
                <a mat-menu-item [routerLink]="'/workouts/' + workout.id">
                    <span>Edit</span>
                    <mat-icon>edit</mat-icon>
                </a>
                <button mat-menu-item (click)="openDeleteDialog()">
                    <span>Delete</span>
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-menu>
        </div>
        <div class="absolute top-0 right-0 w-full h-full flex justify-center items-center " *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>
</a>
