<form
  class="p-5 flex flex-column"
  [formGroup]="_formGroup"
  (ngSubmit)="submitForm()"
>
  <div class="flex flex-col justify-center gap-5 my-3">
    <app-video-uploader [_control]="_formGroup.controls.video"></app-video-uploader>
    <app-image-uploader [_control]="imgThumb"></app-image-uploader>
  </div>

  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Title</mat-label>
    <input
      type="text"
      [formControl]="name"
      matInput
      (focus)="name.markAsUntouched()"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(name)">
      {{ ValidationHelperService.getErrorMessage(name) }}
    </mat-error>
  </mat-form-field>
  <app-select-exercise-category
    [_control]="category"
  ></app-select-exercise-category>

    <app-select-body-part [_control]="bodyPart"></app-select-body-part>

  <app-exercise-type [_control]="type" [action]="action"></app-exercise-type>
  <div class="flex justify-between gap-5 border-b-2 my-5">
    <p class="text-4xl py-3">Exercise configuration</p>
      <form [formGroup]="configFormGroup" class="flex-grow" *ngIf="action === EAction.Edit">
          <mat-form-field appearance="outline" class="w-full">
              <mat-label>Select configuration</mat-label>
              <mat-select [formControl]="configFormGroup.controls.selectedConfig">
                  <mat-option *ngFor="let config of exercise.configs" [value]="config">
                      {{ getConfigTitle(config) }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </form>
  </div>

  <app-exercise-config-form
    [_formGroup]="exerciseConfig"
    [_formControl]="type"
  ></app-exercise-config-form>

  <div class="flex justify-center items-center">
    <button mat-flat-button color="primary">Submit</button>
  </div>
</form>
