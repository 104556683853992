<form
  class="p-5 flex flex-column"
  [formGroup]="_formGroup"
  (ngSubmit)="submitForm()"
>
  <h1 class="mb-5">
    {{ action === EAction.Add ? "Create" : "Edit" }} paired exercise
  </h1>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Title</mat-label>
    <input
      type="text"
      [formControl]="name"
      matInput
      (focus)="name.markAsUntouched()"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(name)">
      {{ ValidationHelperService.getErrorMessage(name) }}
    </mat-error>
  </mat-form-field>
  <app-select-exercise-category
    [_control]="category"
  ></app-select-exercise-category>
  <app-exercise-type [_control]="type" [action]="action"></app-exercise-type>
    <div class="flex justify-between gap-5 border-b-2 my-5">
        <p class="text-4xl py-3">Exercise configuration</p>
        <form [formGroup]="configFormGroup" class="flex-grow" *ngIf="action === EAction.Edit">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Select configuration</mat-label>
                <mat-select [formControl]="configFormGroup.controls.selectedConfig">
                    <mat-option *ngFor="let config of exercise.pairedExerciseConfigs" [value]="config">
                        {{ getConfigTitle(config) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="First exercise">
      <div class="p-5">
        <div class="flex flex-col justify-center gap-5 my-3">
          <app-video-uploader
            [_control]="_formGroup.controls.firstExercise.controls.video"
          ></app-video-uploader>
          <app-image-uploader
            [_control]="_formGroup.controls.firstExercise.controls.imgThumb"
          ></app-image-uploader>
        </div>
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Title</mat-label>
          <input
            type="text"
            [formControl]="firstExercise.controls.name"
            matInput
            (focus)="firstExercise.controls.name.markAsUntouched()"
          />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(firstExercise.controls.name)
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                firstExercise.controls.name
              )
            }}
          </mat-error>
        </mat-form-field>
          <app-select-body-part [_control]="firstExercise.controls.bodyPart"></app-select-body-part>
        <app-exercise-config-form
          [_formGroup]="firstExercise.controls.config"
          [_formControl]="type"
        ></app-exercise-config-form>
      </div>
    </mat-tab>
    <mat-tab label="Second exercise">
      <div class="flex flex-col justify-center gap-5 my-3">
        <app-video-uploader
          [_control]="secondExercise.controls.video"
        ></app-video-uploader>
        <app-image-uploader
          [_control]="secondExercise.controls.imgThumb"
        ></app-image-uploader>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Title</mat-label>
        <input
          type="text"
          [formControl]="secondExercise.controls.name"
          matInput
          (focus)="secondExercise.controls.name.markAsUntouched()"
        />
        <mat-error
          *ngIf="
            ValidationHelperService.isInvalid(secondExercise.controls.name)
          "
        >
          {{
            ValidationHelperService.getErrorMessage(
              secondExercise.controls.name
            )
          }}
        </mat-error>
      </mat-form-field>
        <app-select-body-part [_control]="secondExercise.controls.bodyPart"></app-select-body-part>

        <app-exercise-config-form
        [_formGroup]="secondExercise.controls.config"
        [_formControl]="type"
      ></app-exercise-config-form>
    </mat-tab>
  </mat-tab-group>

  <div class="flex justify-center items-center">
    <button mat-flat-button color="primary">Submit</button>
  </div>
</form>
