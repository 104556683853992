import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IFullPairedExerciseForm} from "../../../../workout-form/WorkoutFormFactory";
import {NgForOf} from "@angular/common";
import {RegularExerciseConfigRowComponent} from "../regular-exercise-row/regular-exercise-config-row/regular-exercise-config-row.component";
import {PairedExerciseConfigRowComponent} from "./paired-exercise-config-row/paired-exercise-config-row.component";

@Component({
  selector: "app-paired-exercise-row",
  templateUrl: "./paired-exercise-row.component.html",
  styleUrls: ["./paired-exercise-row.component.scss"],
  imports: [
    NgForOf,
    RegularExerciseConfigRowComponent,
    PairedExerciseConfigRowComponent
  ],
  standalone: true
})
export class PairedExerciseRowComponent {
  @Input() _form: FormGroup<IFullPairedExerciseForm>;

}
