<div class="flex flex-col gap-3 p-5 w-full min-w-[800px] h-full">
    <h2>Edit playlist</h2>
    <app-playlist-form [_form]="_form"></app-playlist-form>
    <div class="flex justify-end flex-grow">
        <div class="flex justify-center items-center gap-3">
            <button
                    type="button"
                    mat-flat-button
                    (click)="openCloseDialog()"
            >
                Cancel
            </button>
            <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    (click)="onSave()"
            >
                Save
            </button>
        </div>
    </div>
</div>
