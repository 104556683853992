import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IFullPairedExerciseForm, IPairedExerciseConfigForm} from "../../../workout-form/WorkoutFormFactory";
import {ExerciseHelper} from "../../../../../core/helpers/exercise.helper";
import {RegularExerciseHelper} from "../../../../../core/forms/helpers/RegularExerciseHelper";

@Component({
  selector: 'app-paired-exercise-config',
  templateUrl: './paired-exercise-config.component.html',
  styleUrls: ['./paired-exercise-config.component.scss']
})
export class PairedExerciseConfigComponent {
  @Input() exerciseForm: FormGroup<IFullPairedExerciseForm>;
  @Input() _form: FormGroup<IPairedExerciseConfigForm>;

  constructor(private exerciseHelper: ExerciseHelper) {
  }


  getFirstConfigTitle(): string {
    const firstExerciseConfigForm = this.exerciseForm.controls.firstExercise.controls.configs.controls.find(config => config.controls.id.value === this._form.controls.firstConfigId.value);
    if(!firstExerciseConfigForm) return '-';
    const firstConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm?.controls.type.value, firstExerciseConfigForm);

    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, firstConfig);
  }

  getSecondConfigTitle(): string {
    const secondExerciseConfigForm = this.exerciseForm.controls.secondExercise.controls.configs.controls.find(config => config.controls.id.value === this._form.controls.secondConfigId.value);
    if(!secondExerciseConfigForm) return "-";

    const secondConfig = RegularExerciseHelper.getConfigRaw(this.exerciseForm?.controls.type.value, secondExerciseConfigForm);
    return this.exerciseHelper.getConfigTitle(this.exerciseForm.controls.type.value, secondConfig);
  }

}
