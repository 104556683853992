import {Component, Input, OnInit} from "@angular/core";
import {IExerciseForm} from "../../../workout-form/WorkoutFormFactory";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {FormGroup} from "@angular/forms";
import {RegularExerciseRowComponent} from "./regular-exercise-row/regular-exercise-row.component";
import {PairedExerciseRowComponent} from "./paired-exercise-row/paired-exercise-row.component";

@Component({
  selector: "app-exercise-row",
  templateUrl: "./exercise-row.component.html",
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    RegularExerciseRowComponent,
    JsonPipe,
    PairedExerciseRowComponent
  ],
  styleUrls: ["./exercise-row.component.scss"]
})
export class ExerciseRowComponent implements OnInit{
  @Input() _form: FormGroup<IExerciseForm>;

  ngOnInit(): void {
  }
}
