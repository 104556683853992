<form class="flex flex-col gap-5 h-full" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" class="w-full" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input type="text" matInput [formControl]="_form.controls.name" />
        <mat-error *ngIf="ValidationHelperService.isInvalid(_form.controls.name)">
            {{ValidationHelperService.getErrorMessage(_form.controls.name)}}
        </mat-error>
    </mat-form-field>
    <div class="flex flex-col gap-3">
        <div class="w-full h-[400px] flex justify-center items-center" *ngIf="_form.controls.playlists.length === 0">
            <div class="flex flex-col text-center">
                <p class="text-2xl font-medium">This workout is empty</p>
                <p class="text-gray-500 text-md max-w-[400px]">
                    You don't have any playlists created. Press the button below to create your first playlist.
                </p>
            </div>
        </div>
        <div class="flex flex-col gap-7" *ngIf="_form.controls.playlists.length > 0">
            <app-playlist-row-form *ngFor="let playlistForm of _form.controls.playlists.controls" (_delete)="deletePlaylist(playlistForm)" [_form]="playlistForm"></app-playlist-row-form>
        </div>
        <button mat-flat-button color="primary" type="button" (click)="addPlaylist()">Add a new playlist in this workout</button>
    </div>
    <div class="flex gap-3 my-3 items-center justify-end">
        <button mat-flat-button color="warn" type="button" (click)="cancel()">
            Cancel
        </button>
        <button mat-flat-button color="primary">
            Save
        </button>
    </div>
</form>
