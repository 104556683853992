<div class="flex justify-between items-center w-full" *ngIf="config.regularExercise">
    <div class="flex justify-between items-center gap-2">
        <mat-icon>drag_indicator</mat-icon>
        <app-regular-exercise-config [_form]="config.regularConfig" [exerciseForm]="config.regularExercise"></app-regular-exercise-config>
    </div>
    <app-exercise-type-badge [type]="config.regularExercise.controls.type.value"></app-exercise-type-badge>
</div>
<div class="flex justify-between items-center w-full" *ngIf="config.pairedExercise">
    <div class="flex justify-between items-center gap-2">
        <mat-icon>drag_indicator</mat-icon>
        <app-paired-exercise-config [_form]="config.pairedConfig" [exerciseForm]="config.pairedExercise"></app-paired-exercise-config>
    </div>
    <app-exercise-type-badge [type]="config.pairedExercise.controls.type.value"></app-exercise-type-badge>
</div>
