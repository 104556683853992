import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {EInjuryLocation} from "../../../shared/interfaces/IInjury";
import {EAction} from "../../../shared/enums/EAction";

export interface IInjuryForm {
    name: FormControl<string>,
    isActive: FormControl<boolean>,
    injuryLocation?: FormControl<EInjuryLocation>,
    grades: FormArray<FormGroup<IInjuryGradeForm>>
}

export interface IInjuryGradeForm {
    id: FormControl<string>,
    name: FormControl<string>,
    isActive: FormControl<boolean>
}

export class InjuryFormFactory {
    public static create(action: EAction = EAction.Add): FormGroup<IInjuryForm>
    {
        if(action === EAction.Add)
        return new FormGroup<IInjuryForm>(
            {
                name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(80)]),
                injuryLocation: new FormControl<EInjuryLocation>(EInjuryLocation.Back, [Validators.required]),
                isActive: new FormControl<boolean>(true, [Validators.required]),
                grades: new FormArray<FormGroup<IInjuryGradeForm>>([])
            }
        )
        else return new FormGroup<IInjuryForm>(
            {
                name: new FormControl<string>("", [Validators.required, Validators.minLength(3), Validators.maxLength(80)]),
                isActive: new FormControl<boolean>(true, [Validators.required]),
                grades: new FormArray<FormGroup<IInjuryGradeForm>>([])
            }
        )
    }
}
