import {Component, Input, OnInit} from "@angular/core";
import {FileUploader} from "ng2-file-upload";
import {MediaControllerService} from "../../../core/api/controllers/media-controller.service";
import {NotificationService} from "../../../core/services/notification.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {MatDialog} from "@angular/material/dialog";
import {IVideoPlayerData, VideoPlayerComponent,} from "../video-player/video-player.component";

@Component({
  selector: "app-video-uploader",
  templateUrl: "./video-uploader.component.html",
  styleUrls: ["./video-uploader.component.scss"],
})
export class VideoUploaderComponent implements OnInit {
  videoUploader: FileUploader;
  isVideoUploading = false;

  @Input() public _control: FormGroup<{
    file: FormControl<File>,
    fileUrl: FormControl<string>
  }>;

  constructor(
    private mediaController: MediaControllerService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initVideoUploader();
  }

  initVideoUploader(): void {
    this.videoUploader = new FileUploader({
      queueLimit: 1,
    });

    this.videoUploader.onAfterAddingFile = ({ file }) => {
      if (file.type.indexOf("video") >= 0 && file.size <= 200000000) {
        let blob = new Blob([file.rawFile], { type: file.type });
        let rawFile = new File([blob], "video");
        this._control.controls.file.setValue(rawFile);
        const data = new FormData();
        data.append("file", file.rawFile);
        this.isVideoUploading = true;
        this.mediaController.mediaUpload(data).subscribe({
          next: (res) => {
            this._control.controls.fileUrl.setValue(res.url);
            this.isVideoUploading = false;
          },
          error: () => {
            this.isVideoUploading = false;
          },
        });
      } else {
        this.notificationService.warn("Invalid video type or size submitted!");
        this._control.setValue(null);
      }
    };
  }

  openPreviewDialog(): void {
    this.dialog.open(VideoPlayerComponent, {
      data: { toView: this._control.value } as IVideoPlayerData,
    });
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
