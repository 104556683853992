import {Component, Inject, OnInit} from "@angular/core";
import {IPlaylistForm} from "../../workout-form/WorkoutFormFactory";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData
} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-edit-playlist-dialog',
  templateUrl: './edit-playlist-dialog.component.html',
  styleUrls: ['./edit-playlist-dialog.component.scss']
})
export class EditPlaylistDialogComponent implements OnInit{
  _form: FormGroup<IPlaylistForm>;

  constructor(
      private dialogRef: MatDialogRef<EditPlaylistDialogComponent>,
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data: {_form: FormGroup<IPlaylistForm> }
  ) {}

  ngOnInit(): void {
    this._form = this.data._form;
  }

  onSave(): void {
    this._form.markAllAsTouched();
    if(this._form.controls.name.valid) this.dialogRef.close(this._form.value);
  }


  openCloseDialog(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "You will lose all your progress."
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (res) => {
        if(res) this.dialog.closeAll();
      }
    })
  }
}
