import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {EAction} from "../../../shared/enums/EAction";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {IPairedExerciseForm} from "../../../shared/interfaces/forms/exercises/IPairedExerciseForm";
import {IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {PairedExerciseHelper} from "../../../core/forms/helpers/PairedExerciseHelper";
import {NotificationService} from "../../../core/services/notification.service";
import {IPairedExercise, IPairedExerciseConfig} from "../save-paired-exercise/save-paired-exercise.component";
import {ExerciseHelper} from "../../../core/helpers/exercise.helper";
import {VideoHelperService, VideoToFramesMethod} from "../../../core/helpers/video-helper.service";
import {MediaControllerService} from "../../../core/api/controllers/media-controller.service";

@Component({
  selector: "app-paired-exercise-form",
  templateUrl: "./paired-exercise-form.component.html",
  styleUrls: ["./paired-exercise-form.component.scss"],
})
export class PairedExerciseFormComponent implements OnInit {
  @Input() _formGroup: FormGroup<IPairedExerciseForm>;
  @Input() action: EAction;
  @Input() exercise: IPairedExercise;
  @Output() _submit = new EventEmitter();

  configFormGroup = new FormGroup({
    selectedConfig: new FormControl(),
  });

  protected readonly ValidationHelperService = ValidationHelperService;

  constructor(
      private notificationService: NotificationService,
      private exerciseHelper: ExerciseHelper,
      private videoHelper: VideoHelperService,
      private mediaController: MediaControllerService
  ) {}

  ngOnInit(): void {
    if(this.action === EAction.Edit) {
      this.configFormGroup.controls.selectedConfig.setValue(this.exercise.pairedExerciseConfigs.find(config => config.id === this.exercise.defaultConfigId));
      this.configFormGroup.controls.selectedConfig.valueChanges.subscribe((data) => {
        this.exercise.defaultConfigId = data.id;
        PairedExerciseHelper.patchFormConfig(this._formGroup, this.exercise);
      })
    }
  }

  async submitForm() {
    if (!PairedExerciseHelper.isFormValid(this._formGroup)) {
      this._formGroup.markAllAsTouched();
      this.notificationService.error("The form is invalid. Please check.");
      return;
    }
    if(!this._formGroup.controls.firstExercise.controls.imgThumb.value || !this._formGroup.controls.firstExercise.controls.imgThumb.value)
    {
      await this.uploadVideoThumbnail();
    }
    this.emitSubmit();
  }

  async uploadVideoThumbnail(): Promise<void> {
    if(!this._formGroup.controls.firstExercise.controls.imgThumb.value) {
      const firstExerciseThumbnail = (await this.videoHelper.getFrames(
          URL.createObjectURL(this._formGroup.controls.firstExercise.controls.video.controls.file.value),
          1,
          VideoToFramesMethod.totalFrames
      ))[0];

      console.log(firstExerciseThumbnail)

      const data = new FormData();
      data.append("file", firstExerciseThumbnail);
      const url = await this.getUrl(data);
      if(url) this._formGroup.controls.firstExercise.controls.imgThumb.setValue(url);
    }
    if(!this._formGroup.controls.secondExercise.controls.imgThumb.value) {
      const secondExerciseThumbnail = (await this.videoHelper.getFrames(
          URL.createObjectURL(this._formGroup.controls.secondExercise.controls.video.controls.file.value),
          1,
          VideoToFramesMethod.totalFrames
      ))[0];

      console.log(secondExerciseThumbnail)

      const data = new FormData();
      data.append("file", secondExerciseThumbnail);
      const url = await this.getUrl(data);
      if(url) this._formGroup.controls.secondExercise.controls.imgThumb.setValue(url);
    }
  }

  getUrl(data: FormData): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      return this.mediaController.mediaUpload(data).subscribe({
      next: (res) => {
        resolve(res.url);
      },
      error: (error) => {
        this.notificationService.error(error);
        reject('');
      },
    })
    });
  }

  emitSubmit(): void {
    this._submit.emit(PairedExerciseHelper.createPayload(this._formGroup));
  }

  getConfigTitle(config: IPairedExerciseConfig): string {
      const firstExerciseConfig = this.exercise.firstExercise.configs.find(c => c.id === config.firstConfigId);
      const secondExerciseConfig = this.exercise.secondExercise.configs.find(c => c.id === config.secondConfigId);
      return `${this.exercise.firstExercise.name} | ${this.exerciseHelper.getConfigTitle(this.exercise.type, firstExerciseConfig)} \n | ${this.exercise.secondExercise.name} | ${this.exerciseHelper.getConfigTitle(this.exercise.type, secondExerciseConfig)}`
  }

  get firstExercise(): FormGroup<IRegularExerciseForm> {
    return this._formGroup.controls["firstExercise"] as FormGroup;
  }

  get secondExercise(): FormGroup<IRegularExerciseForm> {
    return this._formGroup.controls["secondExercise"] as FormGroup;
  }

  get type(): FormControl {
    return this._formGroup.controls["type"] as FormControl;
  }

  get name(): FormControl {
    return this._formGroup.controls["name"] as FormControl;
  }

  get category(): FormControl {
    return this._formGroup.controls["category"] as FormControl;
  }

  protected readonly EAction = EAction;
}
