import {Component, Input} from "@angular/core";
import {IFullRegularExerciseForm} from "../../../../workout-form/WorkoutFormFactory";
import {FormGroup} from "@angular/forms";
import {RegularExerciseConfigRowComponent} from "./regular-exercise-config-row/regular-exercise-config-row.component";
import {NgForOf} from "@angular/common";

@Component({
  selector: "app-regular-exercise-row",
  templateUrl: "./regular-exercise-row.component.html",
  styleUrls: ["./regular-exercise-row.component.scss"],
  standalone: true,
  imports: [
    RegularExerciseConfigRowComponent,
    NgForOf
  ]
})
export class RegularExerciseRowComponent {
  @Input() _form: FormGroup<IFullRegularExerciseForm>;
}
